import React, { Component } from "react";

export default class footer extends Component {
  render() {
    return (
      <div className="site-footer" id="footer">
        <div className="container">
          <ul>
            <li><a href ={"/static/regulamento-interno.pdf"} target = "_blank">Regulamento Interno</a></li>
            <li><a href ={"/static/plano-contingência.pdf"} target = "_blank">Plano de Contingência COVID-19</a></li>
          </ul>
          {/* <div className="name">
          <span>{this.props.siteName}</span>
          </div> */}
        </div>
      </div>
    );
  }
}
